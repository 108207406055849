import React, { useState } from "react";
import { Card, Placeholder } from "react-bootstrap";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis } from "recharts";

import { useSession } from "../../contexts/SessionContext";
import translate from "../../services/Translate";
import DstButton from "../DstButton/DstButton";
import { DstGraphProps } from "./DstGraph.d";
import { calculateNextIndex, calculatePrevIndex, createVisibilityToggler, getLogTimeValue } from "./DstGraph.function";

const CustomTooltipUserLogTIme = ({
  active,
  payload,
  language,
}: {
  active?: boolean;
  payload?: any[];
  language: string;
}) => {
  if (active && payload && !!payload?.length) {
    return (
      <div className="text-center p-3 white-background border">
        <p className="m-regular">{translate(language, "COMPONENTS.DST_GRAPH.LOGTIME")}</p>
        <p className="m-regular">{payload[0].payload.name}</p>
        <p className="m-regular" style={{ color: payload[0].color }}>
          {payload[0].name} : {getLogTimeValue(payload[0].value)}
        </p>
        <p className="m-regular green-font">
          {payload[0].payload.cohortName} : {getLogTimeValue(payload[0].payload.cohortValue)}
        </p>
      </div>
    );
  }
  return null;
};

/**
 * `DstGraph` displays a responsive bar chart with configurable data keys and colors.
 * It supports pagination through the dataset with prev/next buttons.
 *
 * @component
 * @property {Object[]} barConfig - Configuration for each bar in the chart, including data key and fill color.
 * @property {number} barsToShow - Number of bars to display at once; controls pagination.
 * @property {string} buttonDivClassName - Class name for the button div.
 * @property {string} buttonPosition - Position of the pagination buttons.
 * @property {string} buttonSize - Size of the pagination buttons.
 * @property {string} buttonType - Type of pagination buttons.
 * @property {Object[]} data - Array of objects representing the data points for the chart.
 * @property {string|number} height - The height of the chart in pixels.
 * @property {string} legendAlign - Horizontal alignment of the legend.
 * @property {boolean} legendDisabled - Whether the legend can be disabled or not.
 * @property {string} legendIconType - Type of icon to display next to the legend.
 * @property {number} legendIconSize - Size of the icon to display next to the legend.
 * @property {string} legendTitle - Title of the legend.
 * @property {string} legendTitleAlign - Alignment of the legend legend title.
 * @property {string} legendTitleColor - Color of the legend legend title.
 * @property {number|string} legendTitleSize - Size of the legend legend title.
 * @property {string} legendTitleStyle - Style of the legend legend title.
 * @property {string} legendVerticalAlign - Vertical alignment of the legend.
 * @property {boolean} mock - Whether to render the component or not.
 * @property {boolean} showMockButton - Whether to show the mock button or not.
 * @property {string} toolTipColor - Color of the tooltip.
 * @property {string} type - Type of data to display.
 * @property {string|number} width - The width of the chart. Can be a percentage or fixed value.
 * @property {string} xAxisDataKey - The data key to use for the x-axis.
 *
 * @example
 * const data = [
 *   { name: 'Mon', uv: 2, pv: 9, amt: 6 },
 *   { name: 'Tue', uv: 5, pv: 11, amt: 1 },
 *   { name: 'Wed', uv: 7, pv: 16, amt: 8 },
 *   { name: 'Thu', uv: 12, pv: 13, amt: 6 },
 *   { name: 'Fri', uv: 4, pv: 2, amt: 15 },
 *   { name: 'Sat', uv: 10, pv: 2, amt: 5 },
 *   { name: 'Sun', uv: 5, pv: 10, amt: 14 },
 * ];
 *
 * const barConfig = [
 *   { dataKey: 'uv', fill: '#8884d8' },
 *   { dataKey: 'pv', fill: '#82ca9d' },
 *   { dataKey: 'amt', fill: '#ffc658' }
 * ];
 *
 * <DstGraph
 *   data={data}
 *   width="100%"
 *   height={300}
 *   buttonDivClassName="d-flex justify-content-center"
 *   buttonPosition="bottom"
 *   buttonType="text"
 *   buttonSize="sm"
 *   barConfig={barConfig}
 *   legendVerticalAlign="top"
 *   legendAlign="right"
 *   legendIconType="circle"
 *   legendIconSize={8}
 *   legendTitleSize="xxs-regular"
 *   legendTitleStyle="x-bold"
 *   toolTipColor="none"
 * />
 */

const DstGraph = ({
  barConfig,
  barsToShow = 5,
  buttonDivClassName,
  buttonPosition = "bottom",
  buttonSize = "sm",
  buttonType = "text",
  data,
  height = 300,
  legendAlign = "right",
  legendDisabled = false,
  legendIconSize = 8,
  legendIconType = "circle",
  legendTitle,
  legendTitleAlign = "left",
  legendTitleColor = "black",
  legendTitleSize,
  legendTitleStyle = "x-bold",
  legendVerticalAlign = "top",
  mock,
  showMockButton = true,
  toolTipColor = "none",
  type,
  width = "100%",
  xAxisDataKey = "name",
}: DstGraphProps) => {
  const [visible, setVisible] = useState<{ [key: string]: boolean }>(
    barConfig.reduce((acc, cur, index) => ({ ...acc, [`${cur.dataKey}-${index}`]: true }), {})
  );
  const [startIndex, setStartIndex] = useState(0);
  const nextSet = () => setStartIndex((prevIndex) => calculateNextIndex(prevIndex, barsToShow, data.length));
  const prevSet = () => setStartIndex((prevIndex) => calculatePrevIndex(prevIndex, barsToShow));
  const toggleVisibility = createVisibilityToggler(setVisible);
  const { language } = useSession();
  const COLOR_PALETTE = ["#4628dd", "#3cd02f", "#861910", "#1b590a", "#8f8fa8"];

  let prevButtonText = translate(language, "COMPONENTS.DST_GRAPH.PREVIOUS");
  let nextButtonText = translate(language, "COMPONENTS.DST_GRAPH.NEXT");
  let prevButtonIcon = "";
  let nextButtonIcon = "";

  if (buttonType === "text") {
    prevButtonText = translate(language, "COMPONENTS.DST_GRAPH.PREVIOUS");
    nextButtonText = translate(language, "COMPONENTS.DST_GRAPH.NEXT");
  } else if (buttonType === "arrow") {
    prevButtonText = "";
    nextButtonText = "";
    prevButtonIcon = "dst-icon-arrow-narrow-left";
    nextButtonIcon = "dst-icon-arrow-narrow-right";
  } else if (buttonType === "caret") {
    prevButtonText = "";
    nextButtonText = "";
    prevButtonIcon = "dst-icon-chevron-left";
    nextButtonIcon = "dst-icon-chevron-right";
  }

  if (mock) {
    return (
      <div>
        {showMockButton && buttonPosition === "top" && (
          <div className={`mb-3 mt-3 d-flex ${buttonDivClassName}`}>
            <DstButton mock btnClass={prevButtonIcon} btnSize={buttonSize} />
            <DstButton mock btnClass={`ms-1 ${nextButtonIcon}`} btnSize="lg" />
          </div>
        )}
        {legendTitle && (
          <Placeholder as={Card} animation="glow">
            <Placeholder as={Card.Body} xs={12} />
          </Placeholder>
        )}
        <Placeholder as={Card} animation="glow" style={{ width: width, height: height, marginTop: "20px" }}>
          <Placeholder as={Card.Body} xs={12} />
        </Placeholder>
        {showMockButton && buttonPosition === "bottom" && (
          <div className={`mt-3 d-flex ${buttonDivClassName}`}>
            <DstButton mock btnClass={prevButtonIcon} btnSize={buttonSize} />
            <DstButton mock btnClass={`ms-1 ${nextButtonIcon}`} btnSize="lg" />
          </div>
        )}
      </div>
    );
  }
  return (
    <React.Fragment>
      {data.length > barsToShow && buttonPosition === "top" && (
        <div className={`mb-3 mt-3 ${buttonDivClassName}`}>
          <DstButton
            value={prevButtonText}
            btnClass={`ms-1 ${prevButtonIcon}`}
            btnSize={buttonSize}
            clickFunction={prevSet}
            disabled={startIndex === 0}
          />
          <DstButton
            value={nextButtonText}
            btnClass={`ms-1 ${nextButtonIcon}`}
            btnSize={buttonSize}
            clickFunction={nextSet}
            disabled={startIndex >= data.length - barsToShow}
          />
        </div>
      )}
      {legendTitle && (
        <figcaption>
          <h1
            className={legendTitleStyle}
            style={{ color: legendTitleColor, fontSize: legendTitleSize, textAlign: legendTitleAlign }}
          >
            {legendTitle}
          </h1>
        </figcaption>
      )}
      <ResponsiveContainer width={width} height={height}>
        <BarChart data={data.slice(startIndex, startIndex + barsToShow)}>
          <XAxis dataKey={xAxisDataKey} tickLine={false} axisLine={false} />
          {type === "userlogtime" ? (
            <Tooltip
              cursor={{ fill: toolTipColor }}
              content={<CustomTooltipUserLogTIme active={true} language={language} />}
            />
          ) : (
            <Tooltip cursor={{ fill: toolTipColor }} />
          )}
          <Legend
            verticalAlign={legendVerticalAlign}
            align={legendAlign}
            iconType={legendIconType}
            iconSize={legendIconSize}
            onClick={(e: any, index: number) => !legendDisabled && toggleVisibility(e.dataKey, index)}
          />

          {barConfig.map((config, index) => (
            <Bar
              key={`bar-${index}`}
              dataKey={config.dataKey}
              fill={config.fill || COLOR_PALETTE[index % COLOR_PALETTE.length]}
              hide={!visible[`${config.dataKey}-${index}`]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      {data.length > barsToShow && buttonPosition === "bottom" && (
        <div className={`mt-3 ${buttonDivClassName}`}>
          <DstButton
            value={prevButtonText}
            btnClass={`ms-1 ${prevButtonIcon}`}
            btnSize={buttonSize}
            clickFunction={prevSet}
            disabled={startIndex === 0}
          />
          <DstButton
            value={nextButtonText}
            btnClass={`ms-1 ${nextButtonIcon}`}
            btnSize={buttonSize}
            clickFunction={nextSet}
            disabled={startIndex >= data.length - barsToShow}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default DstGraph;
